<template>
    <div class="container">
        <div
            @click="goback"
            style="font-size: 14px; margin-bottom: 10px; cursor: pointer; width: 50px"
        >
            <i class="el-icon-back"></i>
            返回
        </div>
        <div class="search" style="display: flex">
            <el-form
                ref="searchform"
                style="flex: 1"
                :inline="true"
                :model="search"
                class="demo-form-inline"
            >
                <el-form-item>
                    <el-select
                        style="width: 150px"
                        clearable
                        v-model="search.appid"
                        @change="Search('condition')"
                        placeholder="appid"
                    >
                        <el-option
                            v-for="item in applist"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        style="width: 150px"
                        clearable
                        v-model="search.command"
                        @change="Search('condition')"
                        placeholder="执行方法"
                    >
                        <el-option label="EMAIL" value="EMAIL"></el-option>
                        <el-option label="PUSH" value="PUSH"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        style="width: 150px"
                        clearable
                        v-model="search.status"
                        @change="Search('condition')"
                        placeholder="状态"
                    >
                        <el-option label="待审核" :value="0"></el-option>
                        <el-option label="审核不通过" :value="1"></el-option>
                        <el-option
                            label="审核通过，未执行"
                            :value="2"
                        ></el-option>
                        <el-option label="执行中" :value="3"></el-option>
                        <el-option label="已完成" :value="4"></el-option>
                        <el-option label="取消执行" :value="5"></el-option>
                        <el-option label="执行失败" :value="6"></el-option>
                        <el-option label="全部" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model.trim="search.operator_id"
                        @change="Search('condition')"
                        placeholder="执行人id"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model.trim="search.start_time"
                        @change="Search('condition')"
                        placeholder="开始时间"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model.trim="search.end_time"
                        @change="Search('condition')"
                        placeholder="结束时间"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item>
          <el-input v-model.trim="search.tag" @change="Search('condition')" placeholder="组成方式"></el-input>
        </el-form-item> -->
            </el-form>
        </div>

        <el-table border v-bind:data="list">
            <el-table-column label="执行id">
                <template v-slot="scope">
                    <span>{{
                        scope.row.task_data ? scope.row.task_data.id : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="平台唯一标识">
                <template v-slot="scope">
                    <span>{{ scope.row.schema_name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="模板名称">
                <template v-slot="scope">
                    <span>{{ scope.row.message_template_head_name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template v-slot="scope">
                    <span>{{
                        scope.row.task_data
                            ? ChangeStatus(scope.row.task_data.status)
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="规则集合">
                <template v-slot="scope">
                    <el-button
                        v-if="scope.row.task_data"
                        type="info"
                        size="mini"
                        @click="LookRule(scope.row.task_data.rules)"
                        >查看</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column label="创建时间">
                <template v-slot="scope">
                    <span>{{
                        scope.row.task_data
                            ? ChangeTime(scope.row.task_data.create_time)
                            : '' + '(东八区)'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="执行时间">
                <template v-slot="scope">
                    <span>{{
                        scope.row.task_data
                            ? ChangeTime(scope.row.task_data.update_time)
                            : '' + '(东八区)'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template v-slot="scope">
                    <el-button
                        v-if="scope.row.explain"
                        type="primary"
                        @click="ViewFailReason(scope.row.explain)"
                        >查看</el-button
                    >
                    <el-button
                        v-if="
                            scope.row.task_data &&
                                scope.row.task_data.status == 2
                        "
                        type="danger"
                        size="mini"
                        @click="DeleteDistributeTask(scope.row)"
                        >取消</el-button
                    >
                    <el-button
                        v-if="
                            scope.row.task_data &&
                                scope.row.task_data.status == 0
                        "
                        type="info"
                        size="mini"
                        @click="CheckTask(scope.row)"
                        >审核</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="page">
            <el-button
                type="text"
                :disabled="predisabled"
                @click="prePage"
                size="mini"
                >上一页</el-button
            >
            <el-button
                type="text"
                :disabled="nextdisabled"
                @click="nextPage"
                size="mini"
                >下一页</el-button
            >
        </div>

        <el-dialog title="查看规则" v-model="lookFormVisible" width="800px">
            <span style="color:red;margin-bottom:10px;"
                >以下时间为东八区时间</span
            >
            <el-form :model="ruleform" :rules="rules" ref="form">
                <el-tabs type="border-card">
                    <el-tab-pane label="用户基础信息">
                        <el-form-item label="登陆起始时间" label-width="130px">
                            <el-input
                                v-model="userBasisform.login_time.start"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="登陆结束时间" label-width="130px">
                            <el-input
                                v-model="userBasisform.login_time.end"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="注册起始时间" label-width="130px">
                            <el-input
                                v-model="userBasisform.register_time.start"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="注册结束时间" label-width="130px">
                            <el-input
                                v-model="userBasisform.register_time.end"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="手机类型" label-width="130px">
                            <el-input
                                v-model="userBasisform.phone_type"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="版本信息" label-width="130px">
                            <el-input
                                v-model="userBasisform.app_version"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="国家" label-width="130px">
                            <el-input
                                v-model="userBasisform.country"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="指定账户">
                        <el-form-item label="账号" label-width="130px">
                            <el-input
                                v-model="designatedAccountform.account"
                                type="textarea"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="用户和设备的关联信息">
                        <el-form-item
                            label-width="50px"
                            v-for="(item, key) in designatedAccountList"
                            label="型号版本"
                            :key="key"
                        >
                            <el-input
                                v-model="item.model"
                                autocomplete="off"
                            ></el-input>
                            <el-input
                                v-model="item.version"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="lookFormVisible = false"
                        >取 消</el-button
                    >
                </span>
            </template>
        </el-dialog>

        <el-dialog title="失败原因" v-model="isShowFailReason" width="800px">
            <div class="failure-reason">
                <p>{{ failReason }}</p>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="isShowFailReason = false"
                        >关 闭</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <el-dialog title="任务审核" v-model="dialogFormVisible" width="600px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="状态：" prop="status" label-width="110px">
                    <el-select v-model="form.status" placeholder="">
                        <el-option label="不通过" :value="1"></el-option>
                        <el-option label="通过" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推送人数：" label-width="110px">
                    <span>{{ actMessageSendCount }}</span>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                    >
                    <el-button type="primary" @click="Validate(CheckConfirm)"
                        >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import util from "../../../util/util"
import { msg_status } from "../../../assets//js/dictionary"
export default {
    name: "",
    props: {},
    setup() {
        return {}
    },
    mixins: [],
    components: {},
    data() {
        return {
            search: {
                id: "",
                action: 2,
                rows: 10,
                schema: "",
                command: "",
                operator_id: "",
                status: -1,
                start_time: "",
                end_time: "",
                tag: "",
            },
            applist: [],
            failReason: '',
            isShowFailReason: false,
            predisabled: false,
            nextdisabled: false,
            list: [],
            form: {
                id: "",
                status: "",
            },
            dialogFormVisible: false,
            rules: {
                status: [
                    { required: true, message: "请选择审核状态", trigger: "blur" },
                ],
            },
            edit: "",
            lookFormVisible: true,
            ruleform: {
                // user_basis
                type: "",
                login_time: {},
                register_time: {},
                phone_type: "",
                app_version: "",
                country: "",
                // designated_account
                account: "",
                // associated_device
                model: "",
                version: "",
            },
            userBasisform: {
                login_time: {
                    start: "",
                    end: "",
                },
                register_time: {
                    start: "",
                    end: "",
                },
                phone_type: "",
                app_version: "",
                country: "",
            },
            designatedAccountform: {
                account: "",
            },
            designatedAccountList: [
                {
                    model: "",
                    version: "",
                },
            ],
            actMessageSendCount: 0
        }
    },
    computed: {},
    watch: {},
    methods: {
        LookRule(item) {
            this.lookFormVisible = true
            this.ruleform = JSON.parse(item)
            this.ruleform.forEach((item) => {
                if (item.type == "user_basis" && item.rule) {
                    this.userBasisform = item.rule
                    if (item.rule.login_time) {
                        this.userBasisform.login_time.start = this.ChangeTime(
                            this.userBasisform.login_time.start
                        )
                        this.userBasisform.login_time.end = this.ChangeTime(
                            this.userBasisform.login_time.end
                        )
                    }
                    if (item.rule.register_time) {
                        this.userBasisform.register_time.start = this.ChangeTime(
                            this.userBasisform.register_time.start
                        )
                        this.userBasisform.register_time.end = this.ChangeTime(
                            this.userBasisform.register_time.end
                        )
                    }
                } else if (item.type == "designated_account" && item.rule) {
                    this.designatedAccountform = item.rule
                } else if (item.type == "associated_device" && item.rule) {
                    this.designatedAccountList = item.rule
                }
            })
        },
        CheckTask(item) {
            this.dialogFormVisible = true
            this.form.id = item.task_data.id
            // this.CheckConfirm(item)
            this.getActMessageSendCount(item.task_data)
        },
        getActMessageSendCount(task_data) {
            this.api
                .ActMessageSendCount({
                    schema: task_data.schema,
                    rules: task_data.rules,
                    task_id: task_data.id
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.actMessageSendCount = res.data.data.count
                        return
                    }
                    this.$message.error("审核失败:" + res.data.msg)
                })
        },
        CheckConfirm() {
            this.api
                .TemplateTaskCheck({
                    id: this.form.id,
                    status: this.form.status,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success("审核成功")
                        this.Search("condition")
                        this.dialogFormVisible = false
                        return
                    }
                    this.$message.error("审核失败:" + res.data.msg)
                })
        },
        GetAppList() {
            this.api
                .ClientList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.applist = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        Search(item) {
            if (item == "condition") {
                this.predisabled = false
                this.nextdisabled = false
                this.search.id = ""
            }
            this.api.TemplateTaskList(this.search).then((res) => {
                if (res.data.code == 200) {
                    if (res.data.data && res.data.data.length) {
                        if (this.search.action == 1) {
                            this.nextdisabled = false
                        } else {
                            this.predisabled = false
                        }
                        this.list = res.data.data
                    } else if (
                        item == "condition" &&
                        ((res.data.data && !res.data.data.length) || !res.data.data)
                    ) {
                        this.list = []
                        this.nextdisabled = true
                        this.predisabled = true
                    } else if (
                        item == "page" &&
                        ((res.data.data && !res.data.data.length) || !res.data.data)
                    ) {
                        if (this.search.action == 1) {
                            this.predisabled = true
                        } else {
                            this.nextdisabled = true
                        }
                    }
                    return
                }
                this.$message.error("获取失败" + res.data.msg)
            })
        },
        prePage() {
            this.search.action = 1
            this.search.id = this.list[0].task_data.id
            this.Search("page")
        },
        nextPage() {
            this.search.action = 2
            this.search.id = this.list[this.list.length - 1].task_data.id
            this.Search("page")
        },
        Validate: function (call) {
            this.$refs["form"].validate((res) => {
                if (res && call) {
                    call()
                }
            })
        },
        AddAtributeTask() {
            this.dialogFormVisible = true
            this.form = {}
            this.edit = "新增"
        },
        Edit(item) {
            this.dialogFormVisible = true
            this.edit = "修改"
            this.api
                .TemplateTaskDetail({
                    id: item.id,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.form = res.data.data
                        this.form.sent_time = res.data.data.sent_time * 1000
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        ChangeTime(item) {
            return util.dateFormat(item)
        },
        DeleteDistributeTask(item) {
            this.$confirm("确认取消该下发任务?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 删除请求
                    this.api
                        .TemplateTaskDelete({
                            id: item.task_data.id,
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                })
                                this.Search("condition")
                                return
                            }
                            this.$message.error("删除失败" + res.data.msg)
                        })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消删除",
                    })
                })
        },
        ChangeStatus(item) {
            return util.handleValueDisplay(msg_status, item)
        },
        goback() {
            this.$router.push("/message/templatemessage")
        },
        ViewFailReason(val) {
            this.isShowFailReason = true
            this.failReason = val
        }
    },
    filters: {},
    mounted() {
        this.search.tag = "message_" + this.$route.params.code
        this.Search("condition")
    },
    created() {
        this.GetAppList()
    },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
  float: right;
  margin-top: 10px;
}
</style>
